.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
    color: #00A854 !important;
}
.ag-body{
    overflow: hidden !important;
}
.ag-center-cols-viewport{
    overflow: hidden !important;
}
.ag-body-viewport{
    width: 100% !important;
}
.ag-root-wrapper.ag-layout-normal{
    height: 96% !important;
}